.brand-logo {
  width: 180px;
}
.lang-btn {
  margin-left: 30px;
  transition: 0.5s ease;
  text-transform: uppercase;
  background: transparent;
  padding: 5px 20px;
  border: 1px solid #4584ca;
  border-radius: 20px;
  color: #4584ca;
  font-family: Roboto-Medium;
}
.lang-btn:hover {
  background: #4584ca;
  color: #fff;
}
.header-sec {
  border-bottom: 1px solid #e2e2e2;
}
.lang-drop {
  border-radius: 20px !important;
  min-width: 85px !important;
}
