html,
body,
div#root {
  margin: 0;
  padding: 0;
}

div#root {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* section.pdf-body {
  padding: 16px;
  background-color: #eee;
  height: 100%;
  margin: 8px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex {
  flex: 1 1 auto;
}

input,
textarea {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px;
  margin: 4px;
}

section.header-bar {
  padding: 16px;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

section.header-bar .header {
  font-size: 1.2em;
}

section.pdf-toolbar {
  padding: 16px;
}

section.pdf-toolbar button {
  background-color: #fff;
  border: 1px solid #555;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

section.pdf-toolbar button:hover,
section.pdf-toolbar button:focus {
  border: 1px solid #333;
  background-color: #eee;
} */
/* .answrtitle .result-title {
  font-size: 32px !important;
}
.answrtitle .result-title {
  font-size: 32px !important;
} */
.ans-title {
  /* line-height: 28px !important; */
  /* font-size: 32px; */
}
.result-title {
  color: #4584ca;
  /* background: #000;
  padding: 20px; */
  font-size: 25px !important;
}
.pdfimg {
  width: 100% !important;
}
.pdfimgleft {
  width: 360px !important;
}
section.flex-row p.survey-questiondd {
  width: 90%;
  /* line-height: 5px; */
  margin-top: 5% !important;
  font-size: 32px;
}
section.flex-row p.survey-ans {
  width: 10%;
  text-align: center;
  font-size: 32px;
  /* background: red; */
}
.ans-title {
  line-height: 28px !important;
}
.result-title {
  color: #4584ca;
}
.pdfimg {
  width: 100%;
}
.titstshow {
  /* margin-left: 250px; */
  text-align: left;
  /* margin-bottom: 15px !important; */
  /* font-size: 20px; */
  /* background: green; */
  /* display: inline-block; */
}
/* .bg-l {
  background: green;
}
.bg-r {
  background: hotpink;
} */
.hidepdf {
  /* display: none; */
  /* height: 0px; */
}
.pdfbtn {
  margin-left: 35%;
  margin-bottom: 22px;
}
.hr {
  border-bottom: 1px solid #96969600;
  width: 100%;
  margin-bottom: 30px;
}
.survey-questiondd {
  /* width: 90%; */
  /* line-height: 5px; */
  /* margin-top: 3% !important; */
  font-size: 20px;
  color: #353535;
  font-family: Roboto-Regular;
  margin-top: 21px !important;
  margin-bottom: 1em !important;
}
.survey-question {
  margin-top: 10px !important;
}
.info-img-left {
  width: 400px;
}
.mb-20 {
  margin-bottom: 40px;
}
.rslt {
  padding-left: 40px;
}
.rslt h1 {
  font-size: 26px !important;
}
