h1 {
  font-size: 36px !important;
}

@font-face {
  font-family: "gotham-bold";

  src: url("./assets/fonts/GothamBold.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/GothamBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gotham-medium";

  src: url("./assets/fonts/GothamMedium.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/GothamMedium.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gotham-light";

  src: url("./assets/fonts/Gotham-Light.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Gotham-Light.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Regular";

  src: url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Roboto-Regular.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Light";

  src: url("./assets/fonts/Roboto-Light.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Roboto-Light.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Medium";

  src: url("./assets/fonts/Roboto-Medium.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Roboto-Medium.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";

  src: url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Roboto-Bold.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: gotham-medium;
  color: #353535;
}

.mb-30 {
  margin-bottom: 30px;
}
p {
  font-family: Roboto-Regular;
  font-size: 16px;
}
button,
input,
optgroup,
select,
textarea {
  font-size: 16px !important;
}
.p-32 {
  font-size: 32px;
}
.bg-grey {
  background-color: #f2f2f2;
}
.bg-light-blue {
  background-color: #4584ca;
}
.bg-dark-blue {
  background-color: #3563ab;
}
.font-white {
  color: #fff;
}
.pad-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pad-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.mt-80 {
  margin-top: 80px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
a {
  font-family: Roboto-Regular;
}
.fw-600 {
  font-weight: 600;
}
.fnt-grey {
  color: #838383;
}
/* .mt-200{margin-top: 200px;} */
/* p:last-child {
  margin-bottom: inherit;
}

p:first-child {
  margin-top:inherit;
} */

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1000px !important;
  }
}
