.footer{background-color: #615d5b;}
.footer-img-right, .footer-img-left{padding: 20px;}
.footer-img-right img, .footer-img-left img{width: 200px;}
.footer-link{    padding: 0;
    list-style: none;
    margin-bottom: 0;
    text-align: center;
    line-height: 30px;}
.footer-link li{display: inline-block;
    color: #fff;
    margin-right: 50px;
}
.footer-link li p{margin-bottom: 0;}
.footer-link li a{color: #fff;}
.mtt-10{margin-top: 10px;}