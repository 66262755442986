/* stepform */
/* ol.progtrckr {
  list-style-type: none;
  padding: 0;
} */
ol.progtrckr {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  width: 100%;
}

/* ol.progtrckr li {
  width: 33.33%;
  display: inline-block;
  line-height: 4.5rem;
  cursor: pointer;
} */
ol.progtrckr li {
  margin-bottom: 50px;
  position: relative;
  float: left;
  width: 33.33%;
  display: inline-block;
  cursor: pointer;
}
/* ol.progtrckr li span {
  color: #353535;
  padding: 0 1.5rem;
  margin-left: 6px;
  float: left;
} */
ol.progtrckr li span {
  color: #353535;
  margin-left: 6px;
  float: left;
  position: absolute;
  left: 40px;
  top: 5px;
}
ol.progtrckr .progtrckr-doing span {
  font-family: Roboto-Medium;
}
ol.progtrckr .progtrckr-done span {
  font-family: Roboto-Medium;
}
ol.progtrckr .progtrckr-todo span {
  font-family: Roboto-Regular;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
}

ol.progtrckr li.progtrckr-done {
  color: black !important;
  /* border-bottom: 4px solid #5cb85c; */
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  top: 18px;
  float: left;
}
/* 
  ol.progtrckr li.progtrckr-todo:before {
  
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
  } */

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #333;
}
ol.progtrckr li:first-child:before {
  content: "1";
}
ol.progtrckr li:nth-child(2):before {
  content: "2";
}
ol.progtrckr li:nth-child(3):before {
  content: "3";
}

/* ol.progtrckr li.progtrckr-doing:before {
  text-align: center;

  color: #fff;
  background-color: #4584ca;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #cccccc;
} */
ol.progtrckr li.progtrckr-doing:before {
  text-align: center;
  color: #fff;
  background-color: #4584ca;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #cccccc;
  position: absolute;
  left: 0;
  top: 0;
}
/* ol.progtrckr li.progtrckr-todo:before {
  text-align: center;

  color: #ccc;
  background-color: #f5f5f5;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #cccccc;
} */

ol.progtrckr li.progtrckr-todo:before {
  position: absolute;
  top: 0;
  text-align: center;
  color: #ccc;
  background-color: #f5f5f5;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #cccccc;
  left: 0;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  /* color: #ff4500; */
}

/* ol.progtrckr li.progtrckr-done:before {
  text-align: center;
  color: #fff;
  background-color: #4584ca;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #2c5786;
} */
ol.progtrckr li.progtrckr-done:before {
  text-align: center;
  color: #fff;
  background-color: #4584ca;
  width: 35px;
  height: 35px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #2c5786;
  position: absolute;
  top: 0;
  left: 0;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}
.hr-line {
  width: 100%;
  height: 1px;
  background: #9c9797;
}
.survey-question {
  color: #353535;
  font-family: Roboto-Regular;
  margin-top: 30px !important;
  margin-bottom: 1em !important;
}
.answer-inputs {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.ans-selection {
  width: 20%;
  float: left;
  text-align: center;
}
.ans-status {
  width: 40%;
  float: left;
  text-align: right;
}
.ans-status p {
  margin-top: 7px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.step-progress .footer-buttons {
  text-align: center;
}
.step-progress .footer-buttons button {
  background-color: #4584ca;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
  font-family: Roboto-Regular;
  padding: 10px 20px;
  border-radius: 40px;
  margin-top: 30px;
  display: inline-block;
  transition: 0.5s ease;
  outline: none;
  border: 0;
}
/* radio btn */
/* HIDE RADIO */
.ans-selection i {
  height: 50px;
  width: 50px;
  font-size: 37px;
  -webkit-text-stroke: 1px white;
}
.ans-selection [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

/* IMAGE STYLES */
[type="radio"] + i {
  cursor: pointer;
  color: #797777;
}

/* CHECKED STYLES */
.like [type="radio"]:checked + i {
  color: #82c74d;
}
.dislike [type="radio"]:checked + i {
  color: #ff6262;
}
.dnknow [type="radio"]:checked + i {
  color: #3c7cc8;
}

/* radio btn end */
/* step form */
.defaultnone {
  display: none;
}

.result-btn {
  background-color: #4584ca;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
  font-family: Roboto-Regular;
  padding: 10px 20px;
  border-radius: 40px;
  margin-top: 30px;
  display: inline-block;
  transition: 0.5s ease;
  outline: none;
  border: 0;
}
.pre-nxt-btn {
  background-color: #4584ca;
  width: 100%;
  color: #fff;
  font-family: Roboto-Regular;
  padding: 10px 20px;
  border-radius: 40px;
  margin-top: 80px;
  display: inline-block;
  transition: 0.2s ease;
  outline: none;
  border: 0;
}
.pre-nxt-btn:hover {
  -webkit-box-shadow: 0px 0px 15px 2px rgba(214, 206, 214, 1);
  -moz-box-shadow: 0px 0px 15px 2px rgba(214, 206, 214, 1);
  box-shadow: 0px 0px 15px 2px rgba(214, 206, 214, 1);
}
.lastsection {
  margin-top: 60px;
  margin-bottom: 60px;
}
.error {
  color: red;
}
.main-heading {
  margin-top: 38px;
}
.progress-sec {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.question-sec {
  padding-bottom: 65px;
}
.ques-heading {
  margin-top: 70px !important;
  margin-bottom: 25px !important;
}
.fa-question {
  -webkit-text-stroke: 4px #fff !important;
}
.remuneration {
  display: block !important;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-family: "Roboto-Regular";
  line-height: 27px;
}

/* checkbox aspect */
.privacy-check [type="checkbox"]:not(:checked) + label:before,
.privacy-check [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect */
.privacy-check [type="checkbox"]:not(:checked) + label:after,
.privacy-check [type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 18px;
  line-height: 0.8;
  color: #353535;
  transition: all 0.2s;
  font-family: "Roboto-Regular";
  font-weight: 600;
}
/* checked mark aspect changes */
.privacy-check [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.privacy-check [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.privacy-check [type="checkbox"]:disabled:not(:checked) + label:before,
.privacy-check [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.privacy-check [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.privacy-check [type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
.privacy-check [type="checkbox"]:checked:focus + label:before,
/* .privacy-check [type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted blue;
} */

/* hover style just for information */
.privacy-check label:hover:before {
  border: 2px solid #4778d9 !important;
}
/* country drop */
.ui.selection.dropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.fluid.dropdown {
  display: block;
  width: 100%;
  min-width: 0;
}
.ui.search.dropdown {
  min-width: "";
}
.ui.selection.dropdown {
  width: 150px;
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #4584ca;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease,
    -webkit-box-shadow 0.1s ease;
}
.ui.selection.dropdown > .text{color:#fff !important;}
.ui.search {
  font-size: 1em;
}
.ui.search {
  position: relative;
}
.ui.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: 0;
  text-align: left;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
}
.ui.search.selection.dropdown > input.search {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
}
.ui.search.dropdown > input.search {
  position: absolute;
  z-index: 2;
}
.ui.search.dropdown > input.search {
  background: none transparent !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: text;
  top: 0;
  left: 1px;
  width: 100%;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: inherit;
}
input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.ui.active.search.dropdown input.search:focus + .text {
  color: rgba(115, 115, 115, 0.87) !important;
}
.ui.default.dropdown:not(.button) > input:focus ~ .text,
.ui.dropdown:not(.button) > input:focus ~ .default.text {
  color: rgba(115, 115, 115, 0.87);
}
.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: rgba(191, 191, 191, 0.87);
}
.ui.search.dropdown.active > .text,
.ui.search.dropdown.visible > .text {
  pointer-events: none;
}
.ui.search.dropdown > .text {
  cursor: text;
  position: relative;
  left: 1px;
  z-index: 3;
}
.ui.dropdown > .text {
  display: inline-block;
  -webkit-transition: none;
  transition: none;
}
.ui.fluid.dropdown > .dropdown.icon {
  float: right;
}
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 1em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.ui.dropdown > .dropdown.icon {
  width: auto;
}
.ui.dropdown > .dropdown.icon {
  font-family: Dropdown;
  line-height: 1;
  height: 1em;
  width: 1.23em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}
.ui.dropdown > .dropdown.icon {
  position: relative;
  width: auto;
  font-size: 0.85714286em;
  margin: 0 0 0 1em;
}
i.icon,
i.icons {
  font-size: 1em;
}
i.icon {
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ui.selection.dropdown .menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  border-top-width: 0 !important;
  width: auto;
  outline: 0;
  margin: 0 -1px;
  min-width: calc(100% + 2px);
  width: calc(100% + 2px);
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.ui.dropdown .menu {
  left: 0;
}
.ui.dropdown .menu {
  cursor: auto;
  position: absolute;
  display: none;
  outline: 0;
  top: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin: 0;
  padding: 0 0;
  background: #fff;
  font-size: 1em;
  text-shadow: none;
  text-align: left;
  -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
}
.transition {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* country drop end */
