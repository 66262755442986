.result-ticker img {
  width: 100%;
}
.result .progress {
  margin-bottom: 25px;
}
.result_percnt {
  margin-top: 100px;
}
.result_percnt h1 {
  color: #4584ca;
}
.result_percnt p {
  font-weight: 300;
}
.bor-left {
  border-left: 1px solid #e2e2e2;
  padding-left: 30px;
}
.download-btn {
  background-color: #4584ca !important;
  color: #fff !important;
  font-family: Roboto-Regular;
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 30px;
  display: inline-block;
  transition: 0.5s ease;
}
.download-btn:hover {
  text-decoration: none;
  background-color: #3568a0;
  color: #fff;
}
.guide_qty_txt {
  margin-bottom: 0;
}
.rating-text .i-icon {
  font-size: 12px;
  border: 1px solid #ababab;
  padding: 0px 6px;
  text-align: center;
  border-radius: 50%;
  font-weight: 700;
  margin-left: 5px;
  color: #353535;
}
.rating-text {
  font-family: Roboto-Regular;
  color: #ababab;
  cursor: pointer;
}
.tooltip1 {
  display: inline;
}

.tooltip1 .tooltiptext {
  font-size: 12px;
  visibility: hidden;
  width: 60%;
  font-family: Roboto-Regular;
  background-color: #e2e2e2;
  color: #353535;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  left: 15px;
  top: -50px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 16%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e2e2e2 transparent transparent transparent;
}
.testsho {
  margin-bottom: 15px !important;
}
.successmsg {
  color: green;
  text-align: center;
}
.errormsg {
  color: red;
  text-align: center;
}
