.testimonials-img{position: relative;
    height: 100%;}

.testimonials-img img{
    width: 80%;
    /* top: 40%;
    transform: translate(-50%,-40%);
    position: absolute;
    left: 50%; */
    
}
.testimonials-test, .testimonials-img{width: 100%;
    padding: 30px;
}

.testimonials .carousel-control-next, .carousel-control-prev{
    width: 20px;
   
}
.testimonials .carousel-indicators{bottom: -15px;}
.testimonials  .carousel-indicators li{width: 15px;
height: 15px;
border-radius: 50%;
background-color: #c1bcbc;}
.testimonials .carousel-indicators .active{background-color: #78dbe8;}
.postion_name{color: #838383;
margin-bottom: 50px !important;}
.company_name{font-family: Roboto-Medium;}
/* .testimonials-test{height: 320px;
overflow: hidden;} */
.testimonial-desc{margin-bottom: 50px;
color: #838383;}