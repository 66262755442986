.starttest{margin-top: 30px;
margin-bottom: 30px;}
.starttest-btn{background-color: #f3d74f;
color: #222;
font-family: "Roboto-Regular";
border-radius: 15px;
width: 100%;}
.starttest-btn:hover{background-color:green;}
.starttest input, .starttest select, .company-size{font-family: "Roboto-Regular";
text-transform: capitalize;
color: #353535;}
.input-outline{overflow: hidden;
border-radius: 15px;}
.company-size{
border-radius:15px; 
}
.country-drp{width: 100%;}
.ant-select-selection-placeholder{font-family: "Roboto-Regular";
    text-transform: capitalize;
    color: #353535;
font-size: 16px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 100% !important;}
    .country-drp.ant-select{height: 37px;
    font-size: 16px;
    font-family: "Roboto-Regular";
    text-transform: capitalize;
color: #495057;}
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height: 37px !important;}
    .ant-select-item-option-content{font-size: 16px;
        font-family: "Roboto-Regular";
        text-transform: capitalize;
        color: #353535;}

    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{height: 100% !important;
    }