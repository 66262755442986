.navbar-nav a{
    line-height: 18px;
    font-size: 18px;
    font-family: Roboto-Medium;
    margin-left: 15px;
    margin-right: 15px;
}
.info-img-left img{width: 240px;
}
.info-img-left{text-align: right;}
.info-img-right{text-align: left;}
.info-img-right img{width: 340px;
}
.main-heading{font-family: gotham-medium;} 
.sec-heading{font-family: gotham-light;
}
.info-img{margin-top: 20px;
margin-bottom: 20px;}